import { Splide } from "@splidejs/splide";

// JavaScript code for the custom web component
class UpsellSlider extends HTMLElement {
    constructor() {
      super();
      this.splideList = this.querySelector('.splide__list');
    }
  
    connectedCallback() {
    if(this.splideList.childElementCount > 0) {
     new Splide(this, {
        perPage    : 1,
        perMove    : 1,
        pagination : false,
        arrowPath  : 'm2.2742.9395 20 19.9999-20 20.0001-2.1149-2.115 17.8851-17.8851-17.8851-17.885 2.1149-2.1149z',
        arrows     : false,
        classes    : {
          arrows: 'splide__arrows',
          prev  : 'splide__arrow--prev your-class-prev -left-0',
		      next  : 'splide__arrow--next your-class-next -right-0',
        },
      }).mount();
    }
    }
  
}
  
  customElements.define('upsell-slider', UpsellSlider);
  